<template>
  <p class="lead">
    <stemble-latex :content="validFeedback"></stemble-latex>
  </p>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ValidFeedback',
  components: {
    StembleLatex,
  },
  props: {
    validFeedback: {
      required: true,
    },
  },
};
</script>
