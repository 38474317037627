<template>
  <v-btn
    variant="outline-success"
    size="lg"
    block
    type="submit"
    color="secondary"
    :disabled="!submitEnabled || submitting"
  >
    <span v-show="!submitting">{{ isTryAgain ? 'Try Again' : 'Submit' }}</span>
    <stemble-loader v-if="submitting" type="inline" />
  </v-btn>
</template>

<script>
import StembleLoader from '@/loading/components/StembleLoader';

export default {
  name: 'SubmitButton',
  components: {StembleLoader},
  props: {
    submitting: {
      type: Boolean,
    },
    submitEnabled: {
      type: Boolean,
    },
    isTryAgain: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
