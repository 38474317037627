<template>
  <div v-if="isFeedback">
    <span class="text-bold">Feedback:</span>
    <valid-feedback v-show="state" class="text-success" :valid-feedback="validFeedback" />
    <invalid-feedback v-show="!state" class="text-danger" :invalid-feedback="invalidFeedback" />
  </div>
</template>

<script>
import ValidFeedback from './ValidFeedback';
import InvalidFeedback from './InvalidFeedback';

export default {
  name: 'ResponseFeedback',
  components: {InvalidFeedback, ValidFeedback},
  props: {
    validFeedback: {},
    invalidFeedback: {},
    state: {
      default: null,
    },
  },
  computed: {
    isFeedback() {
      if (this.state !== null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
